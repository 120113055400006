var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('span', [_vm.profile ? _c('editable-page', {
    attrs: {
      "title": _vm.profile && _vm.profile.getHandle() ? _vm.profile.getHandle() : _vm.name,
      "imageUrl": _vm.profile.getProfile() && _vm.profile.getProfile().getImageUrl(),
      "apiStatusList": [_vm.getProfileStatus],
      "edit": true,
      "loginRequired": ""
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pl-5"
        }, [_c('v-col', [_c('stats-col', {
          staticClass: "font-weight-medium",
          attrs: {
            "title": "Joined",
            "cols": 6,
            "md": 3,
            "lg": 2
          }
        }, [_vm._v(" " + _vm._s(_vm.profile && _vm.formatDate(_vm.profile.getJoinedOn())) + " ")])], 1), _c('v-col', {
          attrs: {
            "offset": 6
          }
        }, [_c('stats-col', {
          attrs: {
            "title": ""
          }
        }, [_c('v-btn', {
          staticClass: "mb-1",
          attrs: {
            "color": "accent"
          },
          on: {
            "click": function click($event) {
              return _vm.profileEditClose();
            }
          }
        }, [_vm._v("Back")])], 1)], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  }), _c('v-tabs', [_c('v-tab', [_vm._v("Personal")]), _c('v-tab', [_vm._v("Competitive")]), _c('v-tab-item', [_c('personal-profile', {
    attrs: {
      "profile": _vm.profile,
      "edit": true,
      "isContentSaved": _vm.isPersonalProfileSaved
    },
    on: {
      "changeHandle": _vm.saveHandle,
      "changeName": _vm.saveName,
      "save": _vm.save
    }
  })], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('competitive-profile', {
    attrs: {
      "competitiveProfiles": _vm.profile && _vm.profile.getProfile() && _vm.profile.getProfile().getCompetitiveProfilesList(),
      "edit": true,
      "isContentVerified": _vm.isHandleVerified,
      "isContentDeleted": _vm.isHandleDeleted
    },
    on: {
      "addCPProfile": _vm.saveCPProfile,
      "removeProfile": _vm.removeCPSite,
      "verifyProfile": _vm.verify
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Contests")]), _c('v-data-table', {
    staticClass: "px-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.profile.getProfile() && _vm.profile.getProfile().getContestStatsList()
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [item.getContentPreview() ? _c('router-link', {
          attrs: {
            "to": '/contests/' + item.getContentPreview().getUrl()
          }
        }, [_vm._v(" " + _vm._s(item.getContentPreview().getTitle()) + " ")]) : _c('span', [_vm._v(" Unknown Contest ")])], 1), _c('td', [item.getContentPreview() ? _c('router-link', {
          attrs: {
            "to": '/contests/' + item.getContentPreview().getUrl() + '/scoreboard'
          }
        }, [_vm._v(" " + _vm._s(item.getRank()) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.getRank()))])], 1), _c('td', [_vm._v(" " + _vm._s(item.getProblemSolved()))])])];
      }
    }], null, false, 1936861757)
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1) : _c('span', [_c('v-row', {
    staticClass: "pa-10"
  }, [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to edit. ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }