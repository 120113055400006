<template>
	<v-card flat outlined>
	<v-card-title>
		Personal Profile
	</v-card-title>
	<v-card-text>
		<v-row>
			<v-col>
				<v-card>
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="6" md="4">
								<v-text-field :readonly="!!profile.getHandle()" label="Handle" :value="profile.getHandle()" @change="changeHandle" @input="editHandle" ></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="6" md="4">
								<v-text-field label="Name" :value="profile.getName()" @change="changeName"></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions v-if="isHandleEdited">
						<v-checkbox v-model = "checked" label="I confirm that the handle name is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"></v-checkbox>
					</v-card-actions>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn :loading=isContentSaved :disabled="isSaveButtonDisabled" color="blue darken-1" text @click="save">Save</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-card-text>
</v-card>
</template>

<script>
export default {
	data() {
       return {
		   isHandleEdited:false,
		   checked:false,
	   }
	},
	props: {
		profile: {
			type: Object,
			required: true,
		},
		isContentSaved:{
           type:Boolean,
		},
	},
	methods: {
		save() {
			this.isHandleEdited = false;
			this.$emit("save");
		},
		changeHandle(handle) {
			this.$emit("changeHandle", handle);
		},
		changeName(name) {
			this.$emit("changeName", name);
		},
		editHandle()
		{
			this.isHandleEdited = true;
		}
	},
	computed : {
		isSaveButtonDisabled()
		{
			if(this.isContentSaved)
			  return true;
			else if(this.isHandleEdited && !this.checked)
			  return true;
			return false;
		},
	}
}
</script>
