var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" Personal Profile ")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": !!_vm.profile.getHandle(),
      "label": "Handle",
      "value": _vm.profile.getHandle()
    },
    on: {
      "change": _vm.changeHandle,
      "input": _vm.editHandle
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "value": _vm.profile.getName()
    },
    on: {
      "change": _vm.changeName
    }
  })], 1)], 1)], 1), _vm.isHandleEdited ? _c('v-card-actions', [_c('v-checkbox', {
    attrs: {
      "label": "I confirm that the handle name is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  })], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.isContentSaved,
      "disabled": _vm.isSaveButtonDisabled,
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }